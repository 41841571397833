import {
  animate,
  animation,
  AnimationReferenceMetadata,
  stagger,
  style,
  transition,
  query,
  group,
  useAnimation,
  animateChild,
  AnimationMetadata,
  trigger,
} from '@angular/animations';

export const STANDARD_EASING = 'cubic-bezier(0.4, 0.0, 0.2, 1)';
export const DECELERATED_EASING = 'cubic-bezier(0.0, 0.0, 0.2, 1)';
export const ACCELERATED_EASING = 'cubic-bezier(0.4, 0.0, 1, 1)';
export const FADE_IN_START = { opacity: 0.0, transform: 'scale(0.8)' };
export const FADE_IN_END = { opacity: 1.0, transform: 'scale(1.0)' };
export const FADE_OUT_START = { opacity: 1.0 };
export const FADE_OUT_END = { opacity: 0.0 };

export const FADE_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style(FADE_IN_START),
  animate(45, style({ opacity: 1.0 })),
  animate(150, style({ transform: 'scale(1.0)' })),
]);

export const FADE_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style(FADE_OUT_START),
  animate(75, style({ opacity: 0.0 })),
]);

export const SLIDE_LEFT_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateX(0)', opacity: 1.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 0.0 })),
    animate(
      `300ms ${STANDARD_EASING}`,
      style({ transform: 'translateX(-30px)' })
    ),
  ]),
]);

export const SLIDE_LEFT_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateX(30px)', opacity: 0.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 1.0 })),
    animate(
      `210ms 90ms ${DECELERATED_EASING}`,
      style({ transform: 'translateX(0)' })
    ),
  ]),
]);

export const SLIDE_RIGHT_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateX(0)', opacity: 1.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 0.0 })),
    animate(
      `300ms ${STANDARD_EASING}`,
      style({ transform: 'translateX(30px)' })
    ),
  ]),
]);

export const SLIDE_RIGHT_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateX(-30px)', opacity: 0.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 1.0 })),
    animate(
      `210ms 90ms ${DECELERATED_EASING}`,
      style({ transform: 'translateX(0)' })
    ),
  ]),
]);

export const SLIDE_UP_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateY(0)', opacity: 1.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 0.0 })),
    animate(
      `300ms ${STANDARD_EASING}`,
      style({ transform: 'translateY(-30px)' })
    ),
  ]),
]);

export const SLIDE_UP_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateY(30px)', opacity: 0.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 1.0 })),
    animate(
      `210ms 90ms ${DECELERATED_EASING}`,
      style({ transform: 'translateY(0)' })
    ),
  ]),
]);

export const DELAYED_SLIDE_UP_IN_ANIMATION: AnimationReferenceMetadata =
  animation([
    style({ transform: 'translateY(30px)', opacity: 0.0 }),
    group([
      animate(`90ms 300ms ${ACCELERATED_EASING}`, style({ opacity: 1.0 })),
      animate(
        `210ms 390ms ${DECELERATED_EASING}`,
        style({ transform: 'translateY(0)' })
      ),
    ]),
  ]);

export const SLIDE_DOWN_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateY(0)', opacity: 1.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 0.0 })),
    animate(
      `300ms ${STANDARD_EASING}`,
      style({ transform: 'translateY(30px)' })
    ),
  ]),
]);

export const SLIDE_DOWN_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateY(-30px)', opacity: 0.0 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 1.0 })),
    animate(
      `210ms 90ms ${DECELERATED_EASING}`,
      style({ transform: 'translateY(0)' })
    ),
  ]),
]);

export const SLIDE_ABOVE_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'scale(1.0)', opacity: 1.0, zIndex: 40 }),
  group([
    animate(`90ms ${ACCELERATED_EASING}`, style({ opacity: 0.0 })),
    animate(`300ms ${STANDARD_EASING}`, style({ transform: 'scale(0.8)' })),
  ]),
]);

export const SLIDE_ABOVE_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateY(100vh)', zIndex: 60 }),
  group([
    animate(
      `300ms ${DECELERATED_EASING}`,
      style({ transform: 'translateY(0)' })
    ),
  ]),
]);

export const SLIDE_BELOW_OUT_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'translateY(0)', opacity: 1.0, zIndex: 60 }),
  group([
    animate(
      `200ms ${ACCELERATED_EASING}`,
      style({ transform: 'translateY(100vh)' })
    ),
  ]),
]);

export const SLIDE_BELOW_IN_ANIMATION: AnimationReferenceMetadata = animation([
  style({ transform: 'scale(0.8)', opacity: 0.0, zIndex: 40 }),
  group([
    animate(
      `90ms ${DECELERATED_EASING}`,
      style({ transform: 'scale(1.0)', opacity: 1.0 })
    ),
  ]),
]);

export const APPEND_ANIMATION: AnimationReferenceMetadata = animation([
  style({ opacity: 0, transform: 'translateY(24px)' }),
  animate(
    `500ms ${DECELERATED_EASING}`,
    style({ opacity: 1, transform: 'none' })
  ),
]);

export const DEFAULT_APPEAR_ANIMATION = trigger('appear', [
  transition(':enter', [
    APPEND_ANIMATION,
    query('@*', animateChild(), { optional: true }),
  ]),
]);

export const APPEND_ANIMATION_STAGGER_CUSTOM: (
  delay: number
) => AnimationReferenceMetadata = (delay) =>
  animation([
    style({ opacity: 0, transform: 'translateY(24px)', zIndex: 20 }),
    stagger(delay, [
      animate(
        `500ms ${DECELERATED_EASING}`,
        style({ opacity: 1, transform: 'none' })
      ),
    ]),
  ]);

export const APPEND_ANIMATION_STAGGER = APPEND_ANIMATION_STAGGER_CUSTOM(30);

export const REMOVE_ANIMATION: AnimationReferenceMetadata = animation([
  style({ opacity: 1 }),
  animate(`200ms ${STANDARD_EASING}`, style({ opacity: 0 })),
]);

export const DEFAULT_STAGGER_ANIMATION = trigger('stagger', [
  transition('* <=> *', [
    query(':enter', useAnimation(APPEND_ANIMATION_STAGGER), { optional: true }),
    query(':leave', useAnimation(REMOVE_ANIMATION), { optional: true }),
  ]),
]);

export const STATIC_SLOW_STAGGER_ANIMATION = trigger('staticSlowStagger', [
  transition('* <=> *', [
    query('div', useAnimation(APPEND_ANIMATION_STAGGER_CUSTOM(300)), {
      optional: true,
    }),
  ]),
]);

export const ROUTE_POPUP_TRANSITION: AnimationMetadata[] = [
  transition('default => *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        'box-sizing': 'border-box',
      }),
    ]),
    query(':leave', animateChild(), { optional: true }),
    group([query(':enter', useAnimation(FADE_IN_ANIMATION))]),
    query(':enter', animateChild()),
  ]),
  transition('* => default', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          'box-sizing': 'border-box',
        }),
      ],
      { optional: true }
    ),
    query(':leave', animateChild(), {
      optional: true,
    }),
    group([
      query(':leave', useAnimation(FADE_OUT_ANIMATION), { optional: true }),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
];

export const X_AXIS_TRANSITION: AnimationMetadata[] = [
  transition(':increment', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          'flex-grow': 1,
        }),
      ],
      { optional: true }
    ),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':enter', useAnimation(SLIDE_LEFT_IN_ANIMATION), {
        optional: true,
      }),
      query(':leave', useAnimation(SLIDE_LEFT_OUT_ANIMATION), {
        optional: true,
      }),
    ]),
    query('@*', animateChild(), { optional: true }),
  ]),
  transition(':decrement', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          'flex-grow': 1,
        }),
      ],
      { optional: true }
    ),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':enter', useAnimation(SLIDE_RIGHT_IN_ANIMATION), {
        optional: true,
      }),
      query(':leave', useAnimation(SLIDE_RIGHT_OUT_ANIMATION), {
        optional: true,
      }),
    ]),
    query('@*', animateChild(), { optional: true }),
  ]),
];

export const Z_AXIS_TRANSITION: AnimationMetadata[] = [
  transition(':increment', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          display: 'block',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':enter', useAnimation(SLIDE_ABOVE_IN_ANIMATION)),
      query(':leave', useAnimation(SLIDE_ABOVE_OUT_ANIMATION)),
    ]),
    query('@*', animateChild({ delay: 300 }), { optional: true }),
  ]),
  transition(':decrement', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          display: 'block',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }),
      ],
      { optional: true }
    ),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':enter', useAnimation(SLIDE_BELOW_IN_ANIMATION)),
      query(':leave', useAnimation(SLIDE_BELOW_OUT_ANIMATION)),
    ]),
    query('@*', animateChild({ delay: 300 })),
  ]),
];

import { trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { X_AXIS_TRANSITION } from './shared/constants/animation';

@Component({
  standalone: true,
  selector: 'reservaties-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [trigger('depthX', X_AXIS_TRANSITION)],
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor(private readonly auth: AuthService) {
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      console.log('isAuthenticated', isAuthenticated);
    });
  }

  public depthX(outlet: RouterOutlet): number {
    return parseInt(outlet.activatedRouteData?.depthX) ?? 0;
  }
}

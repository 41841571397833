import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class AppTitleService implements Resolve<string> {
  constructor(private acccountService: AccountService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): string | Observable<string> | Promise<string> {
    return this.acccountService.account$.pipe(
      map((account) => {
        const parts = route.data.titlePrefix ? [route.data.titlePrefix] : [];
        parts.push(`${account.name} Reservaties`);
        return parts.join(' | ');
      }),
      startWith('Reservatie App')
    );
  }
}

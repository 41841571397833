import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';

export const ignoreAbortedInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 0) {
        // Do nothing for HTTP 0 errors
        console.warn('Ignoring aborted request', req);
        return of();
      } else {
        // Pass other errors through
        throw error;
      }
    })
  );
};

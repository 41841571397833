import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import en from '@angular/common/locales/en';
import nlBe from '@angular/common/locales/nl-BE';
import {
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HotToastModule } from '@ngneat/hot-toast';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { PRODUCTION_BOOTSTRAP } from './app/constants';
import { AccountService } from './app/shared/services/account.service';
import { ProductionService } from './app/shared/services/production.service';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { IconService } from './app/shared/services/icon.service';
import { ignoreAbortedInterceptor } from './app/ignore-aborted.interceptor';

registerLocaleData(nlBe);
registerLocaleData(en);

if (environment.production) {
  Sentry.init({
    dsn: 'https://b6f8716a871e48729864f9e6c104a252@o4504496418193408.ingest.sentry.io/4504496421404672',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://yourserver.io/api'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    environment: environment.production ? 'production' : 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      appRoutes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
    ),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([ignoreAbortedInterceptor])
    ),
    importProvidersFrom(HotToastModule.forRoot()),
    importProvidersFrom(
      AuthModule.forRoot({
        domain: environment.auth.domain,
        clientId: environment.auth.clientId,
        cacheLocation: 'localstorage',
        audience: environment.auth.audience,
        redirect_uri: window.location.origin,
        httpInterceptor: {
          allowedList: [
            {
              uriMatcher: (uri) => {
                if (!uri.startsWith('/api')) {
                  return false;
                }

                if (
                  uri.includes('bootstrap') ||
                  uri.startsWith('/api/account/current')
                ) {
                  return false;
                }

                return true;
              },
              allowAnonymous: true,
            },
          ],
        },
      }),
      NgxMatomoTrackerModule.forRoot({
        trackerUrl: 'https://matomo.nienormaal.be',
        siteId: '2',
        acceptDoNotTrack: true,
        runOutsideAngularZone: true,
      }),
      NgxMatomoRouterModule
    ),
    {
      provide: LOCALE_ID,
      useValue: 'nl-BE',
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: PRODUCTION_BOOTSTRAP,
      useFactory: (
        productionService: ProductionService,
        accountService: AccountService
      ) => {
        productionService.loadBootstrap();
        accountService.refresh();
      },
      deps: [ProductionService, AccountService],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService, IconService],
      multi: true,
    },
  ],
}).catch((err) => console.error(err));

import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AppTitleService } from './shared/services/app-title.service';
import { RootCanActivateService } from './shared/services/root-can-activate.service';

export const appRoutes: Routes = [
  {
    path: 'production',
    title: AppTitleService,
    loadComponent: () => import('./production/production.component'),
    data: { depthX: 0 },
  },
  {
    path: 'production/:id',
    title: AppTitleService,
    loadComponent: () => import('./production/production.component'),
    data: { depthX: 0 },
  },
  {
    path: 'reservation/:reservationId',
    title: AppTitleService,
    loadComponent: () => import('./reservation/reservation.component'),
    data: { depthX: 1 },
  },
  {
    path: 'admin',
    title: AppTitleService,
    loadChildren: () => import('./admin/admin.routes'),
    data: { depthX: 1, titlePrefix: 'Administratie' },
  },
  {
    path: 'public',
    title: 'Reservatie App',
    loadComponent: () => import('./public/public.component'),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RootCanActivateService],
    component: AppComponent,
  },
];

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RootCanActivateService implements CanActivate {
  constructor(private router: Router) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const parts = window.location.host.split('.');
    if ((parts.length <= 2 || parts[0] === 'www') && parts[1] !== 'localhost') {
      return this.router.createUrlTree(['/public']);
    }
    return this.router.createUrlTree(['/production']);
  }
}

export const environment = {
  production: true,
  url: 'https://reservatie.app',
  stackdriver: {
    key: 'AIzaSyDc2ZtVPMtP3vBkj_HxWxEbdj0_JtqILXE',
    projectId: 'reservatie-app',
  },
  auth: {
    domain: 'reservaties.eu.auth0.com',
    clientId: '8U1U37SJbnNSiWX1AlcRcyJnuvaTTgmc',
    audience: 'https://reservatie.app',
  },
};

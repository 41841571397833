import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IAccountViewDto,
  IFileUploadResponse,
} from '@reservaties/api-interfaces';
import { BehaviorSubject, filter, Observable, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AccountService {
  private readonly ROOT_URL = '/api/account';

  private readonly accountSubject = new BehaviorSubject<IAccountViewDto | null>(
    null
  );
  public readonly account$: Observable<IAccountViewDto> = this.accountSubject
    .asObservable()
    .pipe(filter((account) => account != null)) as Observable<IAccountViewDto>;

  constructor(private http: HttpClient) {
    // Fetch account from localstorage cache first, only if we're on a subdomain
    if (document.location.host.split('.').length >= 3) {
      const storedAccount: string | null = localStorage.getItem('account');
      if (storedAccount) {
        this.accountSubject.next(JSON.parse(storedAccount));
      }

      this.account$.pipe(untilDestroyed(this)).subscribe((account) => {
        localStorage.setItem('account', JSON.stringify(account));
      });
    }
  }

  get(): Observable<IAccountViewDto> {
    return this.http.get<IAccountViewDto>(`${this.ROOT_URL}/current`);
  }

  set(account: IAccountViewDto): void {
    this.accountSubject.next(account);
  }

  setDefaultProductionId(id: string): Observable<IAccountViewDto> {
    return this.http
      .post<IAccountViewDto>(`${this.ROOT_URL}/set-default-production`, {
        id,
      })
      .pipe(
        tap((account) => this.set(account)),
        shareReplay(1)
      );
  }

  upload(file: File): Observable<HttpEvent<IFileUploadResponse>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<IFileUploadResponse>(
      `${this.ROOT_URL}/upload`,
      formData,
      { reportProgress: true, observe: 'events' }
    );
  }

  public refresh() {
    this.get().subscribe({
      next: (account) => this.accountSubject.next(account),
      error: (err) => {
        console.warn('Error fetching account', err);
      },
    });
  }
}
